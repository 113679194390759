import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";

const StudioPage = ({ data }) => (
  <Layout title="Lo Studio">
    <section className="section">
      <div className="container">
        <h1 className="title is-2">Lo Studio</h1>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div
          className="content is-medium"
          dangerouslySetInnerHTML={{ __html: data.studioContent.html }}
        />
      </div>
    </section>
  </Layout>
);

StudioPage.propTypes = {
  data: PropTypes.shape({ studioContent: PropTypes.object }).isRequired
};

export const query = graphql`
  query StudioQuery {
    studioContent: markdownRemark(
      frontmatter: { key: { eq: "content" }, title: { eq: "Studio" } }
    ) {
      html
    }
  }
`;

export default StudioPage;
